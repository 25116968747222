import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MainLayout from "./MainLayout";

import logoEma from "../../images/ema_assets/logo.svg";
import logoKeematic from "../../images/logo.svg";

function AuthLayout({ children, modules, ...props }) {
  const [logo, setLogo] = useState(null);
  const name = modules.organisation_name;

  useEffect(() => {
    if (modules && modules.organisation_name) {
      chooseIcon(modules.organisation_name);
    }
  }, []);

  const chooseIcon = (name) => {
    switch (name) {
      case "Keematic":
        setLogo(logoKeematic);
        break;
      case "Ema":
        setLogo(logoEma);
        break;
      default:
        setLogo(logoKeematic);
    }
  };

  const commonStyles = {
    container: {
      position: "relative",
      height: "100%",
      minHeight: "100%",
      backgroundColor: "#4158D0",
    },
    logo: {
      display: "block",
      position: "absolute",
      bottom: 0,
      backgroundColor: "transparent",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  };

  const styles = {
    container: {
      ...commonStyles.container,
      backgroundImage: "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
    },

    logo: {
      ...commonStyles.logo,
      left: 0,
      height: "64px",
      width: "64px",
      backgroundImage: `url(${logo})`,
      backgroundSize: "auto 35px",
    },
  };

  const stylesEma = {
    container: {
      ...commonStyles.container,
      backgroundImage: "linear-gradient(43deg, rgba(84,189,189,1) 25%, rgba(255,205,0,1) 100%)",
    },

    logo: {
      ...commonStyles.logo,
      left: 10,
      height: "48px",
      width: "48px",
      backgroundImage: `url(${logoEma})`,
    },
  };

  const appliedStyles = name === "Ema" ? stylesEma : styles;

  return (
    <MainLayout {...props}>
      <div style={appliedStyles.container}>
        {modules.organisation_name === "Ema"
          ? (
            <a
              style={appliedStyles.logo}
              target="_blank"
              rel="noreferrer"
              href="https://ema-mobilite.fr/"
            />
          )
          : (
            <a
              style={appliedStyles.logo}
              target="_blank"
              rel="noreferrer"
              href={`http://www.${name.toLowerCase()}.com`}
            />
          )}
        {children}
        <p
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            color: "#FFF",
          }}
        >
          {modules.organisation_name !== "Keematic"
            ? "© 2024"
            : `© 2018 - ${dayjs().format("YYYY")}.`} Tous droits réservés à {modules.organisation_name} SAS |{" "}
          <a
            style={{ color: "#FFF" }}
            href="https://www.keematic.com/rgpd"
            target="_blank"
            rel="noreferrer"
          >
            Politique de gestion des données personnelles
          </a>
        </p>
      </div>
    </MainLayout>
  );
}

function mapStateToProps(state) {
  return {
    modules: state.modules.modules,
  };
}

export default connect(mapStateToProps)(AuthLayout);
