import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { configure as configureApi } from "../boot/api";
import { configure as configureApiV2 } from "../boot/apiv2";
import { load as loadConfig } from "../boot/config";

import { setModules } from "../actions/modules";

function ConfigLoader({ children, setModules }) {
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const cfg = await loadConfig();
        configureApi(cfg.api_url);
        configureApiV2(cfg.api_url);
        setModules(cfg.modules);

        // Config and init system pay if active
        if (cfg.modules.booking_payment && cfg.modules.payment_system_pay_mode) {
          const scriptSystemPay1 = document.createElement("script");
          scriptSystemPay1.src =
            "https://static.systempay.fr/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js";
          if (cfg.modules.payment_system_pay_mode === "production") {
            scriptSystemPay1.setAttribute(
              "kr-public-key",
              "61360304:publickey_qbFumdrEmd31u4detPk6H85pGrAMECYqMys5yLv4nwbgw",
            );
          } else {
            scriptSystemPay1.setAttribute(
              "kr-public-key",
              "61360304:testpublickey_9GMMI9gFhg5acATVXDXc3vn5OUKVbyJsa6xlfVAandv5X",
            );
          }
          document.head.appendChild(scriptSystemPay1);

          const scriptSystemPay2 = document.createElement("script");
          scriptSystemPay2.src = "https://static.systempay.fr/static/js/krypton-client/V4.0/ext/classic.js";
          document.head.appendChild(scriptSystemPay2);
        }
      } catch (err) {
        console.error(err);
        setErrored(true);
      }
      setLoading(false);
    })();
  }, []);

  if (errored) {
    return (
      <div>
        App failed to fetch configuration. If you are an administrator, please check configuration.
      </div>
    );
  }

  if (loading) {
    return null;
  }

  return children;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setModules }, dispatch);
}

export default connect(null, mapDispatchToProps)(ConfigLoader);
