import React from "react";
import { connect } from "react-redux";

import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";

function AppBreadcrump({ items, modules, dispatch, ...otherProps }) {
  const location = useLocation();
  const isInPublic = location.pathname.includes("/public");

  const setTextColor = () =>
    modules.organisation_name === "Keematic"
      && window.location.host.split(".")[0] !== "chu-caen"
      && isInPublic
      ? "white"
      : "";

  const sections = items.map((item) => (
    <Breadcrumb.Item key={[item.link, item.label].join("")}>
      {item.link && item.link.length
        ? (
          <Link style={{ color: setTextColor() }} to={item.link}>
            {item.label}
          </Link>
        )
        : (
          item.label
        )}
    </Breadcrumb.Item>
  ));

  return <Breadcrumb {...otherProps}>{sections}</Breadcrumb>;
}

function mapStateToProps(state) {
  return {
    items: state.breadcrump.items,
    modules: state.modules.modules,
  };
}

export default connect(mapStateToProps)(AppBreadcrump);
